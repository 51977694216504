export const afterGrindingROoFShaft = {
	name: 'afterGrindingROoFShaft',
	label: 'After Grinding Run-Out of Shaft',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const goNoGo = {
	name: 'goNoGo',
	label: 'Go No-Go',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const blueMatching = {
	name: 'blueMatching',
	label: 'Blue Matching',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const spacerSizeOfNNBearing = {
	name: 'spacerSizeOfNNBearing',
	label: 'Spacer Size of NN Bearing',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const housingDepth = {
	name: 'housingDepth',
	label: 'Housing Depth',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingStackLength = {
	name: 'bearingStackLength',
	label: 'Bearing Stack Length',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const preLoadOfCover = {
	name: 'preLoadOfCover',
	label: 'Pre-Load of Cover',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const boreRO = {
	name: 'boreRO',
	label: 'Bore Run-Out',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const frontBearingTol = {
	name: 'frontBearingTol',
	label: 'Front Bearing Tolerance',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rearSideRO = {
	name: 'rearSideRO',
	label: 'Rear Side Run-Out',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const faceROHSK = {
	name: 'faceROHSK',
	label: 'Face Run-Out HSK',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const mandrelROWithStud = {
	name: 'mandrelROWithStud',
	label: 'Mandrel Run-Out with Stud',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const mandrelROWithDrawbar = {
	name: 'mandrelROWithDrawbar',
	label: 'Mandrel Run-Out with Drawbar',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const clampingForce = {
	name: 'clampingForce',
	label: 'Clamping Force',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const axialPlay = {
	name: 'axialPlay',
	label: 'Axial Play',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const radialPlay = {
	name: 'radialPlay',
	label: 'Radial Play',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const axialFloat = {
	name: 'axialFloat',
	label: 'Axial Float',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rearBearingTol = {
	name: 'rearBearingTol',
	label: 'Rear Bearing Tolerance',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const totalShaftHeightAfterRework = {
	name: 'totalShaftHeightAfterRework',
	label: 'Total Shaft Height After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const FinalInspectionComponents = {
	afterGrindingROoFShaft,
	goNoGo,
	blueMatching,
	spacerSizeOfNNBearing,
	housingDepth,
	bearingStackLength,
	preLoadOfCover,
	boreRO,
	frontBearingTol,
	rearSideRO,
	faceROHSK,
	mandrelROWithStud,
	mandrelROWithDrawbar,
	clampingForce,
	axialPlay,
	radialPlay,
	axialFloat,
	rearBearingTol,
	totalShaftHeightAfterRework,
};
