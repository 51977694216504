import React, { useState } from 'react';
import axios from 'axios';
import { Button, Box, Typography, CircularProgress } from '@mui/material';

function ImageUpload() {
	const [selectedImage, setSelectedImage] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [uploadSuccess, setUploadSuccess] = useState(null);

	// Handle file selection
	const handleImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setSelectedImage(event.target.files[0]);
			setUploadSuccess(null); // Reset success message on new selection
		}
	};

	// Upload image to the server
	const uploadImage = async () => {
		if (!selectedImage) return;

		const formData = new FormData();
		formData.append('image', selectedImage);

		try {
			setUploading(true);
			const response = await axios.post('/your-upload-endpoint', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			setUploading(false);
			setUploadSuccess(response.data.message || 'Image uploaded successfully!');
		} catch (error) {
			setUploading(false);
			console.error('Error uploading image:', error);
			setUploadSuccess('We are presently working on image upload function.');
		}
	};

	// Clear the selected image
	const handleClearImage = () => {
		setSelectedImage(null);
		setUploadSuccess(null);
	};

	return (
		<Box display='flex' flexDirection='column' gap={2}>
			<div
				style={{
					display: 'flex',
					gap: '24px',
					borderBottom: `${selectedImage ? '1px solid lightgray' : ''}`,
					paddingBottom: `${selectedImage ? '24px' : ''}`,
				}}
			>
				<Typography variant='h6'>Upload Image</Typography>
				<input
					accept='image/*'
					type='file'
					style={{ display: 'none' }}
					id='upload-image'
					onChange={handleImageChange}
				/>
				<label htmlFor='upload-image'>
					<Button variant='outlined' component='span'>
						{selectedImage ? 'Replace' : 'Select'}
					</Button>
				</label>
			</div>

			{selectedImage && (
				<Box
					mt={2}
					p={2}
					sx={{ border: '1px solid lightgray', borderRadius: '8px' }}
				>
					<Typography variant='subtitle1'>{selectedImage.name}</Typography>
					<img
						src={URL.createObjectURL(selectedImage)}
						alt='Preview'
						style={{ width: '100%', maxWidth: '300px', marginTop: '10px' }}
					/>
					<Box mt={2} display='flex' gap={3}>
						<Button
							variant='text'
							color='secondary'
							onClick={handleClearImage}
							sx={{ mt: 1 }}
						>
							Clear Image
						</Button>
						<Button
							variant='contained'
							color='primary'
							onClick={uploadImage}
							disabled={uploading}
						>
							{uploading ? <CircularProgress size={24} /> : 'Upload Image'}
						</Button>
					</Box>
					{uploadSuccess && (
						<Typography
							mt={2}
							color={uploadSuccess.includes('successfully') ? 'green' : 'brown'}
						>
							{uploadSuccess}
						</Typography>
					)}
				</Box>
			)}
		</Box>
	);
}

export default ImageUpload;
