import {
	bearingShaftODFront_pv,
	bearingShaftODFront_result,
	bearingShaftODFront_remark,
	bearingShaftODFront_afterRework,
	bearingShaftODRear_pv,
	bearingShaftODRear_result,
	bearingShaftODRear_remark,
	bearingShaftODRear_afterRework,
	bearingHousingBoreFront_pv,
	bearingHousingBoreFront_result,
	bearingHousingBoreFront_remark,
	bearingHousingBoreFront_afterRework,
	bearingHousingBoreRear_pv,
	bearingHousingBoreRear_result,
	bearingHousingBoreRear_remark,
	bearingHousingBoreRear_afterRework,
	lockSleeveInterferenceFront_pv,
	lockSleeveInterferenceFront_result,
	lockSleeveInterferenceFront_remark,
	lockSleeveInterferenceFront_afterRework,
	lockSleeveInterferenceRear_pv,
	lockSleeveInterferenceRear_result,
	lockSleeveInterferenceRear_remark,
	lockSleeveInterferenceRear_afterRework,
	distanceSleeveClearance_pv,
	distanceSleeveClearance_result,
	distanceSleeveClearance_remark,
	distanceSleeveClearance_afterRework,
	distanceSleeveParallelity_pv,
	distanceSleeveParallelity_result,
	distanceSleeveParallelity_remark,
	distanceSleeveParallelity_afterRework,
	lockSleeveSquareness_pv,
	lockSleeveSquareness_result,
	lockSleeveSquareness_remark,
	lockSleeveSquareness_afterRework,
	runOutBearingODFront_pv,
	runOutBearingODFront_result,
	runOutBearingODFront_remark,
	runOutBearingODFront_afterRework,
	runOutBearingODRear_pv,
	runOutBearingODRear_result,
	runOutBearingODRear_remark,
	runOutBearingODRear_afterRework,
	housingConcentricity_pv,
	housingConcentricity_result,
	housingConcentricity_remark,
	housingConcentricity_afterRework,
	roOnPulleyDia_pv,
	roOnPulleyDia_result,
	roOnPulleyDia_remark,
	roOnPulleyDia_afterRework,
	roOnBearingRestingFace_pv,
	roOnBearingRestingFace_result,
	roOnBearingRestingFace_remark,
	roOnBearingRestingFace_afterRework,
	drawBarClampingForce_pv,
	drawBarClampingForce_result,
	drawBarClampingForce_remark,
	drawBarClampingForce_afterRework,
	pulleyCouplingInterferenceFront_pv,
	pulleyCouplingInterferenceFront_result,
	pulleyCouplingInterferenceFront_remark,
	pulleyCouplingInterferenceFront_afterRework,
	pulleyCouplingInterferenceRear_pv,
	pulleyCouplingInterferenceRear_result,
	pulleyCouplingInterferenceRear_remark,
	pulleyCouplingInterferenceRear_afterRework,
	shaftTotalHeight_pv,
	shaftTotalHeight_result,
	shaftTotalHeight_remark,
	shaftTotalHeight_afterRework,
	roOnShaftFrontSpace_pv,
	roOnShaftFrontSpace_result,
	roOnShaftFrontSpace_remark,
	roOnShaftFrontSpace_afterRework,
	rotorInterference_pv,
	rotorInterference_result,
	rotorInterference_remark,
	rotorInterference_afterRework,
	shaftBalancingValuesPlaneOne,
	shaftBalancingValuesPlaneTwo,
	shaftBalancingValuesRemark,
	distanceSleeveParallelity_dia,
	distanceSleeveClearance_dia,
	lockSleeveInterferenceRear_dia,
	lockSleeveInterferenceFront_dia,
	bearingHousingBoreRear_dia,
	bearingHousingBoreFront_dia,
	bearingShaftODRear_dia,
	bearingShaftODFront_dia,
	lockSleeveSquareness_dia,
} from './InProcessInspectionComponents';

export const InProcessInspectionReport = [
	{
		groupHeader: 'Bearing Shaft OD Front',
		components: [
			bearingShaftODFront_dia,
			bearingShaftODFront_pv,
			bearingShaftODFront_result,
			bearingShaftODFront_remark,
			bearingShaftODFront_afterRework,
		],
	},
	{
		groupHeader: 'Bearing Shaft OD Rear',
		components: [
			bearingShaftODRear_dia,
			bearingShaftODRear_pv,
			bearingShaftODRear_result,
			bearingShaftODRear_remark,
			bearingShaftODRear_afterRework,
		],
	},
	{
		groupHeader: 'Bearing Housing Bore Front ',
		components: [
			bearingHousingBoreFront_dia,
			bearingHousingBoreFront_pv,
			bearingHousingBoreFront_result,
			bearingHousingBoreFront_remark,
			bearingHousingBoreFront_afterRework,
		],
	},
	{
		groupHeader: 'Bearing Housing Bar Rear ',
		components: [
			bearingHousingBoreRear_dia,
			bearingHousingBoreRear_pv,
			bearingHousingBoreRear_result,
			bearingHousingBoreRear_remark,
			bearingHousingBoreRear_afterRework,
		],
	},
	{
		groupHeader: 'Lock Sleeve Interference Front',
		components: [
			lockSleeveInterferenceFront_dia,
			lockSleeveInterferenceFront_pv,
			lockSleeveInterferenceFront_result,
			lockSleeveInterferenceFront_remark,
			lockSleeveInterferenceFront_afterRework,
		],
	},
	{
		groupHeader: 'Lock Sleeve Interference Rear',
		components: [
			lockSleeveInterferenceRear_dia,
			lockSleeveInterferenceRear_pv,
			lockSleeveInterferenceRear_result,
			lockSleeveInterferenceRear_remark,
			lockSleeveInterferenceRear_afterRework,
		],
	},
	{
		groupHeader: 'Distance Sleeve Clearance ',
		components: [
			distanceSleeveClearance_dia,
			distanceSleeveClearance_pv,
			distanceSleeveClearance_result,
			distanceSleeveClearance_remark,
			distanceSleeveClearance_afterRework,
		],
	},
	{
		groupHeader: 'Distance Sleeve Parallelity ',
		components: [
			distanceSleeveParallelity_dia,
			distanceSleeveParallelity_pv,
			distanceSleeveParallelity_result,
			distanceSleeveParallelity_remark,
			distanceSleeveParallelity_afterRework,
		],
	},
	{
		groupHeader: 'Lock Sleeve Squareness',
		components: [
			lockSleeveSquareness_dia,
			lockSleeveSquareness_pv,
			lockSleeveSquareness_result,
			lockSleeveSquareness_remark,
			lockSleeveSquareness_afterRework,
		],
	},
	{
		groupHeader: 'Runout on Bearing OD(mm) Front ',
		components: [
			runOutBearingODFront_pv,
			runOutBearingODFront_result,
			runOutBearingODFront_remark,
			runOutBearingODFront_afterRework,
		],
	},
	{
		groupHeader: 'Runout on Bearing OD (mm) Rear ',
		components: [
			runOutBearingODRear_pv,
			runOutBearingODRear_result,
			runOutBearingODRear_remark,
			runOutBearingODRear_afterRework,
		],
	},
	{
		groupHeader: 'Housing Concentricity (mm) ',
		components: [
			housingConcentricity_pv,
			housingConcentricity_result,
			housingConcentricity_remark,
			housingConcentricity_afterRework,
		],
	},
	{
		groupHeader: ' R/O on pulley Dia (mm)',
		components: [
			roOnPulleyDia_pv,
			roOnPulleyDia_result,
			roOnPulleyDia_remark,
			roOnPulleyDia_afterRework,
		],
	},
	{
		groupHeader: 'R/O on Bearing Resting Face (mm) ',
		components: [
			roOnBearingRestingFace_pv,
			roOnBearingRestingFace_result,
			roOnBearingRestingFace_remark,
			roOnBearingRestingFace_afterRework,
		],
	},
	{
		groupHeader: 'Drawbar Clamping Force (Kg-f) ',
		components: [
			drawBarClampingForce_pv,
			drawBarClampingForce_result,
			drawBarClampingForce_remark,
			drawBarClampingForce_afterRework,
		],
	},
	{
		groupHeader: 'Pulley/coupling interference (mm) Front',
		components: [
			pulleyCouplingInterferenceFront_pv,
			pulleyCouplingInterferenceFront_result,
			pulleyCouplingInterferenceFront_remark,
			pulleyCouplingInterferenceFront_afterRework,
		],
	},
	{
		groupHeader: 'Pulley/coupling interference (mm) Rear',
		components: [
			pulleyCouplingInterferenceRear_pv,
			pulleyCouplingInterferenceRear_result,
			pulleyCouplingInterferenceRear_remark,
			pulleyCouplingInterferenceRear_afterRework,
		],
	},
	{
		groupHeader: 'Shaft Total Height (mm) ',
		components: [
			shaftTotalHeight_pv,
			shaftTotalHeight_result,
			shaftTotalHeight_remark,
			shaftTotalHeight_afterRework,
		],
	},
	{
		groupHeader: 'R/O on Shaft Front Space (mm) ',
		components: [
			roOnShaftFrontSpace_pv,
			roOnShaftFrontSpace_result,
			roOnShaftFrontSpace_remark,
			roOnShaftFrontSpace_afterRework,
		],
	},
	{
		groupHeader: 'Rotor Interference ',
		components: [
			rotorInterference_pv,
			rotorInterference_result,
			rotorInterference_remark,
			rotorInterference_afterRework,
		],
	},
	{
		groupHeader: 'Shaft Balancing Values ',
		components: [
			shaftBalancingValuesPlaneOne,
			shaftBalancingValuesPlaneTwo,
			shaftBalancingValuesRemark,
		],
	},
];
/*

  */
