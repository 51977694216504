export const frontArrangement = {
	name: 'frontArrangement',
	label: 'Front Arrangement',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const frontDetails = {
	name: 'frontDetails',
	label: 'Front Details',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const frontQty = {
	name: 'frontQty',
	label: 'Front Quantity',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rearArrangement = {
	name: 'rearArrangement',
	label: 'Rear Arrangement',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rearDetails = {
	name: 'rearDetails',
	label: 'Rear Details',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rearQty = {
	name: 'rearQty',
	label: 'Rear Quantity',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const mechanicalSealDetails = {
	name: 'mechanicalSealDetails',
	label: 'Mechanical Seal Details',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const mechanicalSealQty = {
	name: 'mechanicalSealQty',
	label: 'Mechanical Seal Quantity',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const NewBearingComponents = {
	frontArrangement,
	frontDetails,
	frontQty,
	rearArrangement,
	rearDetails,
	rearQty,
	mechanicalSealDetails,
	mechanicalSealQty,
};
