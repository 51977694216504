import { useLocation, useNavigate } from 'react-router-dom';
import useUIStore from '../stores/useUiStore';
import { useTheme } from '@emotion/react';
import { useMediaQuery } from '@mui/material';

function useHelperFunctions() {
	const uiState = useUIStore((state) => state);
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const isTabletOrBelow = useMediaQuery('(max-width: 1025px)');

	function returnUrl(paramsArray = []) {
		if (!Array.isArray(paramsArray)) {
			throw new Error('Input must be an array');
		}
		let url = '/' + paramsArray.join('/');
		console.log(url);
		return url;
	}

	function navigateUrl(stateObject, paramsArray) {
		if (!Array.isArray(paramsArray)) {
			throw new Error('Input must be an array');
		}
		let array = paramsArray.map((e) => stateObject[e]);
		console.log(array);
		return array;
	}

	return {
		navigate,
		theme,
		isOutletOpen: uiState.isOutletOpen,
		isSidebarOpen: uiState.isSidebarOpen,
		showOutlet: uiState.showOutlet,
		hideOutlet: uiState.hideOutlet,
		showSidebar: uiState.showSidebar,
		hideSidebar: uiState.hideSidebar,
		createdNewOrder: uiState.createdNewOrder,
		setCreatedNewOrderFalse: uiState.setCreatedNewOrderFalse,
		setCreatedNewOrderTrue: uiState.setCreatedNewOrderTrue,
		isTabletOrBelow,
		setOrderIndex: uiState.setOrderIndex,
		clearOrderIndex: uiState.clearOrderIndex,
		currentOrder: uiState.orderIndex,
		returnUrl: returnUrl,
		navigateUrl: navigateUrl,
		location: location,
	};
}
export default useHelperFunctions;
