import useHelperFunctions from '../helpers/functions';
import './layout.css';
import MainArea from './TwoScreens/MainArea/MainArea';
import Navbar from './TwoScreens/Navbar/Navbar';
import SideBar from './TwoScreens/Sidebar/SideBar';

function SplitScreen({ children }) {
	const { isTabletOrBelow, isSidebarOpen, hideSidebar } = useHelperFunctions();

	return (
		<section className='page'>
			<div className='navContainer'>
				<Navbar />
			</div>
			{isTabletOrBelow && isSidebarOpen && (
				<div className='blurBelow' onClick={hideSidebar}></div>
			)}
			<main className='pageContainer'>
				<SideBar
					isSidebarOpen={isSidebarOpen}
					isTabletOrBelow={isTabletOrBelow}
				/>
				<MainArea isTabletOrBelow={isTabletOrBelow}>{children}</MainArea>
			</main>
		</section>
	);
}

export default SplitScreen;
