import {
	afterGrindingROoFShaft,
	goNoGo,
	blueMatching,
	spacerSizeOfNNBearing,
	housingDepth,
	bearingStackLength,
	preLoadOfCover,
	boreRO,
	frontBearingTol,
	rearSideRO,
	faceROHSK,
	mandrelROWithStud,
	mandrelROWithDrawbar,
	clampingForce,
	axialPlay,
	radialPlay,
	axialFloat,
	rearBearingTol,
	totalShaftHeightAfterRework,
} from './FinalInspectionComponents';

export const FinalInspectionReport = [
	{
		groupHeader: 'Final Inspection',
		components: [
			afterGrindingROoFShaft,
			goNoGo,
			blueMatching,
			spacerSizeOfNNBearing,
			housingDepth,
			bearingStackLength,
			preLoadOfCover,
			boreRO,
			frontBearingTol,
			rearSideRO,
			faceROHSK,
			mandrelROWithStud,
			mandrelROWithDrawbar,
			clampingForce,
			axialPlay,
			radialPlay,
			axialFloat,
			rearBearingTol,
			totalShaftHeightAfterRework,
		],
	},
];
