import { Typography } from '@mui/material';

function SectionSubHeader({ s, children }) {
	return (
		<Typography variant='h6' mb={2} sx={{ fontWeight: 'normal', ...s }}>
			{children}
		</Typography>
	);
}

export default SectionSubHeader;
