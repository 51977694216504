export const customerName = {
	name: 'customerName',
	label: 'Customer Name',
	type: 'text',
	url: '/customerName',
	validation: {
		type: 'string',
		required: true,
		minLength: 2,
	},
};

export const customerLocation = {
	name: 'customerLocation',
	label: 'Customer Location',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
		minLength: 2,
	},
};

export const isFirstTimeCustomer = {
	name: 'isFirstTimeCustomer',
	label: 'Is First Time Customer?',
	type: 'radio',
	options: [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['Yes', 'No'],
	},
};

export const isPaymentOutstanding = {
	name: 'isPaymentOutstanding',
	label: 'Is Payment Outstanding?',
	type: 'radio',
	options: [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['Yes', 'No'],
	},
};

export const isCustomerInformedAboutOutstanding = {
	name: 'isCustomerInformedAboutOutstanding',
	label: 'Have you informed the customer about outstanding payment?',
	type: 'radio',
	options: [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['Yes', 'No'],
	},
};

export const spindleDispatchOnlyAfterClearance = {
	name: 'spindleDispatchOnlyAfterClearance',
	label: 'Spindle Dispatch Only After Clearance?',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'no', label: 'No' },
		{ value: 'na', label: 'Not Applicable' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['Yes', 'No', 'Not Applicable'],
	},
};

export const whyDispatchWithoutClearance = {
	name: 'whyDispatchWithoutClearance',
	label: 'Why Dispatch Without Clearance?',
	type: 'longtext',
	validation: {
		type: 'string',
		optional: true,
		maxLength: 500,
	},
};

export const spindleMake = {
	name: 'spindleMake',
	label: 'Spindle Make',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
		minLength: 1,
	},
};

export const spindleSerialNumber = {
	name: 'spindleSerialNumber',
	label: 'Spindle Serial Number',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
		minLength: 1,
	},
};

export const spindleModel = {
	name: 'spindleModel',
	label: 'Spindle Model',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
		minLength: 1,
	},
};

export const taperSpecifications = {
	name: 'taperSpecifications',
	label: 'Taper Specifications',
	type: 'text',
	validation: {
		type: 'string',
		optional: true,
	},
};

export const spindleMaxRPM = {
	name: 'spindleMaxRPM',
	label: 'Spindle Max RPM',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const spindleReceivedDate = {
	name: 'spindleReceivedDate',
	label: 'Spindle Received Date',
	type: 'date',
	validation: {
		type: 'date',
		required: true,
	},
};

export const spindleType = {
	name: 'spindleType',
	label: 'Spindle Type',
	type: 'dropdown',
	options: [
		{ value: 'beltDriven', label: 'Belt Driven' },
		{ value: 'directDriven', label: 'Direct Driven' },
		{ value: 'gearDriven', label: 'Gear Driven' },
		{ value: 'integrated', label: 'Integrated' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['beltDriven', 'directDriven', 'gearDriven', 'integrated'],
	},
};

export const natureOfProblem = {
	name: 'natureOfProblem',
	label: 'Nature of Problem',
	type: 'longtext',
	validation: {
		type: 'string',
		required: true,
		maxLength: 1000,
	},
};

export const financeApproval = {
	name: 'financeApproval',
	label: 'Finance Approval',
	type: 'radio',
	options: [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['Yes', 'No', true, false],
	},
};

export const salesApproval = {
	name: 'salesApproval',
	label: 'Sales Approval',
	type: 'radio',
	options: [
		{ value: true, label: 'Yes' },
		{ value: false, label: 'No' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['Yes', 'No', true, false],
	},
};

export const IncomingAlertComponents = {
	customerName,
	customerLocation,
	isFirstTimeCustomer,
	isPaymentOutstanding,
	isCustomerInformedAboutOutstanding,
	spindleDispatchOnlyAfterClearance,
	whyDispatchWithoutClearance,
	spindleMake,
	spindleSerialNumber,
	spindleModel,
	taperSpecifications,
	spindleMaxRPM,
	spindleReceivedDate,
	spindleType,
	natureOfProblem,
	financeApproval,
	salesApproval,
};
