import { z } from 'zod';

export default function generateZodSchema(config) {
	const schema = {};

	config.forEach((field) => {
		const { name, type, validation, subfields } = field;

		if (type === 'group') {
			schema[name] = generateZodSchema(subfields);
		} else {
			schema[name] = generateZodField(validation, type);
		}
	});

	return z.object(schema);
}

function generateZodField(validation, fieldType) {
	if (!validation) return z.any();

	let fieldSchema;

	switch (validation.type) {
		case 'string':
			fieldSchema = z.string({ required_error: 'This field is required' });

			if (validation.minLength) {
				fieldSchema = fieldSchema.min(validation.minLength, {
					message: `Must be at least ${validation.minLength} characters`,
				});
			}

			if (validation.maxLength) {
				fieldSchema = fieldSchema.max(validation.maxLength, {
					message: `Must be at most ${validation.maxLength} characters`,
				});
			}

			if (validation.email) {
				fieldSchema = fieldSchema.email('Invalid email address');
			}

			if (validation.url) {
				fieldSchema = fieldSchema.url('Invalid URL');
			}

			if (validation.regex) {
				fieldSchema = fieldSchema.regex(
					new RegExp(validation.regex.pattern),
					validation.regex.message
				);
			}

			break;

		case 'number':
			fieldSchema = z.number({ required_error: 'This field is required' });

			if (validation.min) {
				fieldSchema = fieldSchema.min(validation.min, {
					message: `Must be at least ${validation.min}`,
				});
			}

			if (validation.max) {
				fieldSchema = fieldSchema.max(validation.max, {
					message: `Must be at most ${validation.max}`,
				});
			}

			break;

		case 'array':
			fieldSchema = z.array(z.any(), {
				required_error: 'This field is required',
			});

			if (validation.minLength) {
				fieldSchema = fieldSchema.min(validation.minLength, {
					message: `Select at least ${validation.minLength} options`,
				});
			}

			if (validation.maxLength) {
				fieldSchema = fieldSchema.max(validation.maxLength, {
					message: `Select at most ${validation.maxLength} options`,
				});
			}

			break;

		case 'date':
			fieldSchema = z
				.preprocess(
					(arg) => (arg ? new Date(arg) : undefined),
					z.date({ required_error: 'This field is required' })
				)
				.refine((date) => !isNaN(date), {
					message: 'Invalid date',
				});
			break;

		default:
			fieldSchema = z.any();
	}

	if (validation.optional) {
		fieldSchema = fieldSchema.optional();
	}

	return fieldSchema;
}
