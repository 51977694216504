export const boxType = {
	name: 'boxType',
	label: 'Box Type',
	type: 'dropdown',
	options: [
		{ value: 'woodenBox', label: 'Wooden Box' },
		{ value: 'cartonBox', label: 'Carton Box' },
		{ value: 'withoutBox', label: 'Without Box' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['woodenBox', 'cartonBox', 'withoutBox'],
	},
};

export const cylinder = {
	name: 'cylinder',
	label: 'Cylinder',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'na', label: 'Not Applicable' },
		{ value: 'missing', label: 'Missing' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['yes', 'na', 'missing'],
	},
};

export const proxySwitch = {
	name: 'proxySwitch',
	label: 'Proxy Switch',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'na', label: 'Not Applicable' },
		{ value: 'missing', label: 'Missing' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['yes', 'na', 'missing'],
	},
};

export const tenon = {
	name: 'tenon',
	label: 'Tenon',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'na', label: 'Not Applicable' },
		{ value: 'missing', label: 'Missing' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['yes', 'na', 'missing'],
	},
};

export const pulley = {
	name: 'pulley',
	label: 'Pulley',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'na', label: 'Not Applicable' },
		{ value: 'missing', label: 'Missing' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['yes', 'na', 'missing'],
	},
};

export const drawbar = {
	name: 'drawbar',
	label: 'Drawbar',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'na', label: 'Not Applicable' },
		{ value: 'missing', label: 'Missing' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['yes', 'na', 'missing'],
	},
};

export const speedSensor = {
	name: 'speedSensor',
	label: 'Speed Sensor',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'na', label: 'Not Applicable' },
		{ value: 'missing', label: 'Missing' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['yes', 'na', 'missing'],
	},
};

export const spindleCondition = {
	name: 'spindleCondition',
	label: 'Spindle Condition',
	type: 'dropdown',
	options: [
		{ value: 'seized', label: 'Seized' },
		{ value: 'free', label: 'Free' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['seized', 'free'],
	},
};

export const bearingNoise = {
	name: 'bearingNoise',
	label: 'Bearing Noise',
	type: 'dropdown',
	options: [
		{ value: 'yes', label: 'Yes' },
		{ value: 'na', label: 'Not Applicable' },
		{ value: 'missing', label: 'Missing' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['yes', 'na', 'missing'],
	},
};

export const others = {
	name: 'others',
	label: 'Others',
	type: 'longtext',
	validation: {
		type: 'string',
		optional: true,
		maxLength: 500,
	},
};

export const CheckSheetComponents = {
	boxType,
	cylinder,
	proxySwitch,
	tenon,
	pulley,
	drawbar,
	speedSensor,
	spindleCondition,
	bearingNoise,
	others,
};
