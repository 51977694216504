export const bearingShaftODFront_pv = {
	name: 'bearingShaftODFront_pv',
	label: 'Bearing Shaft OD Front (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODFront_result = {
	name: 'bearingShaftODFront_result',
	label: 'Bearing Shaft OD Front (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODFront_remark = {
	name: 'bearingShaftODFront_remark',
	label: 'Bearing Shaft OD Front (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODFront_afterRework = {
	name: 'bearingShaftODFront_afterRework',
	label: 'Bearing Shaft OD Front After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODRear_pv = {
	name: 'bearingShaftODRear_pv',
	label: 'Bearing Shaft OD Rear (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODRear_result = {
	name: 'bearingShaftODRear_result',
	label: 'Bearing Shaft OD Rear (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODRear_remark = {
	name: 'bearingShaftODRear_remark',
	label: 'Bearing Shaft OD Rear (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODRear_afterRework = {
	name: 'bearingShaftODRear_afterRework',
	label: 'Bearing Shaft OD Rear After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreFront_pv = {
	name: 'bearingHousingBoreFront_pv',
	label: 'Bearing Housing Bore Front (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreFront_result = {
	name: 'bearingHousingBoreFront_result',
	label: 'Bearing Housing Bore Front (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreFront_remark = {
	name: 'bearingHousingBoreFront_remark',
	label: 'Bearing Housing Bore Front (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreFront_afterRework = {
	name: 'bearingHousingBoreFront_afterRework',
	label: 'Bearing Housing Bore Front After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreRear_pv = {
	name: 'bearingHousingBoreRear_pv',
	label: 'Bearing Housing Bore Rear (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreRear_result = {
	name: 'bearingHousingBoreRear_result',
	label: 'Bearing Housing Bore Rear (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreRear_remark = {
	name: 'bearingHousingBoreRear_remark',
	label: 'Bearing Housing Bore Rear (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreRear_afterRework = {
	name: 'bearingHousingBoreRear_afterRework',
	label: 'Bearing Housing Bore Rear After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceFront_pv = {
	name: 'lockSleeveInterferenceFront_pv',
	label: 'Lock Sleeve Interference Front (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceFront_result = {
	name: 'lockSleeveInterferenceFront_result',
	label: 'Lock Sleeve Interference Front (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceFront_remark = {
	name: 'lockSleeveInterferenceFront_remark',
	label: 'Lock Sleeve Interference Front (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceFront_afterRework = {
	name: 'lockSleeveInterferenceFront_afterRework',
	label: 'Lock Sleeve Interference Front After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceRear_pv = {
	name: 'lockSleeveInterferenceRear_pv',
	label: 'Lock Sleeve Interference Rear (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceRear_result = {
	name: 'lockSleeveInterferenceRear_result',
	label: 'Lock Sleeve Interference Rear (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceRear_remark = {
	name: 'lockSleeveInterferenceRear_remark',
	label: 'Lock Sleeve Interference Rear (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceRear_afterRework = {
	name: 'lockSleeveInterferenceRear_afterRework',
	label: 'Lock Sleeve Interference Rear After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveClearance_pv = {
	name: 'distanceSleeveClearance_pv',
	label: 'Distance Sleeve Clearance (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveClearance_result = {
	name: 'distanceSleeveClearance_result',
	label: 'Distance Sleeve Clearance (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveClearance_remark = {
	name: 'distanceSleeveClearance_remark',
	label: 'Distance Sleeve Clearance (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveClearance_afterRework = {
	name: 'distanceSleeveClearance_afterRework',
	label: 'Distance Sleeve Clearance After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveParallelity_pv = {
	name: 'distanceSleeveParallelity_pv',
	label: 'Distance Sleeve Parallelity (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveParallelity_result = {
	name: 'distanceSleeveParallelity_result',
	label: 'Distance Sleeve Parallelity (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveParallelity_remark = {
	name: 'distanceSleeveParallelity_remark',
	label: 'Distance Sleeve Parallelity (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveParallelity_afterRework = {
	name: 'distanceSleeveParallelity_afterRework',
	label: 'Distance Sleeve Parallelity After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveSquareness_pv = {
	name: 'lockSleeveSquareness_pv',
	label: 'Lock Sleeve Squareness (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveSquareness_result = {
	name: 'lockSleeveSquareness_result',
	label: 'Lock Sleeve Squareness (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveSquareness_remark = {
	name: 'lockSleeveSquareness_remark',
	label: 'Lock Sleeve Squareness (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveSquareness_afterRework = {
	name: 'lockSleeveSquareness_afterRework',
	label: 'Lock Sleeve Squareness After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODFront_pv = {
	name: 'runOutBearingODFront_pv',
	label: 'Run-Out Bearing OD Front (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODFront_result = {
	name: 'runOutBearingODFront_result',
	label: 'Run-Out Bearing OD Front (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODFront_remark = {
	name: 'runOutBearingODFront_remark',
	label: 'Run-Out Bearing OD Front (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODFront_afterRework = {
	name: 'runOutBearingODFront_afterRework',
	label: 'Run-Out Bearing OD Front After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODRear_pv = {
	name: 'runOutBearingODRear_pv',
	label: 'Run-Out Bearing OD Rear (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODRear_result = {
	name: 'runOutBearingODRear_result',
	label: 'Run-Out Bearing OD Rear (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODRear_remark = {
	name: 'runOutBearingODRear_remark',
	label: 'Run-Out Bearing OD Rear (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const runOutBearingODRear_afterRework = {
	name: 'runOutBearingODRear_afterRework',
	label: 'Run-Out Bearing OD Rear After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const housingConcentricity_pv = {
	name: 'housingConcentricity_pv',
	label: 'Housing Concentricity (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const housingConcentricity_result = {
	name: 'housingConcentricity_result',
	label: 'Housing Concentricity (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const housingConcentricity_remark = {
	name: 'housingConcentricity_remark',
	label: 'Housing Concentricity (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const housingConcentricity_afterRework = {
	name: 'housingConcentricity_afterRework',
	label: 'Housing Concentricity After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnPulleyDia_pv = {
	name: 'roOnPulleyDia_pv',
	label: 'Run-Out on Pulley Diameter (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnPulleyDia_result = {
	name: 'roOnPulleyDia_result',
	label: 'Run-Out on Pulley Diameter (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnPulleyDia_remark = {
	name: 'roOnPulleyDia_remark',
	label: 'Run-Out on Pulley Diameter (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnPulleyDia_afterRework = {
	name: 'roOnPulleyDia_afterRework',
	label: 'Run-Out on Pulley Diameter After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnBearingRestingFace_pv = {
	name: 'roOnBearingRestingFace_pv',
	label: 'Run-Out on Bearing Resting Face (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnBearingRestingFace_result = {
	name: 'roOnBearingRestingFace_result',
	label: 'Run-Out on Bearing Resting Face (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnBearingRestingFace_remark = {
	name: 'roOnBearingRestingFace_remark',
	label: 'Run-Out on Bearing Resting Face (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnBearingRestingFace_afterRework = {
	name: 'roOnBearingRestingFace_afterRework',
	label: 'Run-Out on Bearing Resting Face After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const drawBarClampingForce_pv = {
	name: 'drawBarClampingForce_pv',
	label: 'Draw Bar Clamping Force (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const drawBarClampingForce_result = {
	name: 'drawBarClampingForce_result',
	label: 'Draw Bar Clamping Force (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const drawBarClampingForce_remark = {
	name: 'drawBarClampingForce_remark',
	label: 'Draw Bar Clamping Force (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const drawBarClampingForce_afterRework = {
	name: 'drawBarClampingForce_afterRework',
	label: 'Draw Bar Clamping Force After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceFront_pv = {
	name: 'pulleyCouplingInterferenceFront_pv',
	label: 'Pulley Coupling Interference Front (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceFront_result = {
	name: 'pulleyCouplingInterferenceFront_result',
	label: 'Pulley Coupling Interference Front (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceFront_remark = {
	name: 'pulleyCouplingInterferenceFront_remark',
	label: 'Pulley Coupling Interference Front (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceFront_afterRework = {
	name: 'pulleyCouplingInterferenceFront_afterRework',
	label: 'Pulley Coupling Interference Front After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceRear_pv = {
	name: 'pulleyCouplingInterferenceRear_pv',
	label: 'Pulley Coupling Interference Rear (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceRear_result = {
	name: 'pulleyCouplingInterferenceRear_result',
	label: 'Pulley Coupling Interference Rear (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceRear_remark = {
	name: 'pulleyCouplingInterferenceRear_remark',
	label: 'Pulley Coupling Interference Rear (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const pulleyCouplingInterferenceRear_afterRework = {
	name: 'pulleyCouplingInterferenceRear_afterRework',
	label: 'Pulley Coupling Interference Rear After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const shaftTotalHeight_pv = {
	name: 'shaftTotalHeight_pv',
	label: 'Shaft Total Height (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const shaftTotalHeight_result = {
	name: 'shaftTotalHeight_result',
	label: 'Shaft Total Height (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const shaftTotalHeight_remark = {
	name: 'shaftTotalHeight_remark',
	label: 'Shaft Total Height (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const shaftTotalHeight_afterRework = {
	name: 'shaftTotalHeight_afterRework',
	label: 'Shaft Total Height After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnShaftFrontSpace_pv = {
	name: 'roOnShaftFrontSpace_pv',
	label: 'Run-Out on Shaft Front Space (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnShaftFrontSpace_result = {
	name: 'roOnShaftFrontSpace_result',
	label: 'Run-Out on Shaft Front Space (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnShaftFrontSpace_remark = {
	name: 'roOnShaftFrontSpace_remark',
	label: 'Run-Out on Shaft Front Space (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const roOnShaftFrontSpace_afterRework = {
	name: 'roOnShaftFrontSpace_afterRework',
	label: 'Run-Out on Shaft Front Space After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rotorInterference_pv = {
	name: 'rotorInterference_pv',
	label: 'Rotor Interference (PV)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rotorInterference_result = {
	name: 'rotorInterference_result',
	label: 'Rotor Interference (Result)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rotorInterference_remark = {
	name: 'rotorInterference_remark',
	label: 'Rotor Interference (Remark)',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const rotorInterference_afterRework = {
	name: 'rotorInterference_afterRework',
	label: 'Rotor Interference After Rework',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const shaftBalancingValuesPlaneOne = {
	name: 'shaftBalancingValuesPlaneOne',
	label: 'Shaft Balancing Values Plane One',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const shaftBalancingValuesPlaneTwo = {
	name: 'shaftBalancingValuesPlaneTwo',
	label: 'Shaft Balancing Values Plane Two',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const shaftBalancingValuesRemark = {
	name: 'shaftBalancingValuesRemark',
	label: 'Shaft Balancing Values Remark',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveParallelity_dia = {
	name: 'distanceSleeveParallelity_dia',
	label: 'Distance Sleeve Parallelity Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const distanceSleeveClearance_dia = {
	name: 'distanceSleeveClearance_dia',
	label: 'Distance Sleeve Clearance Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceRear_dia = {
	name: 'lockSleeveInterferenceRear_dia',
	label: 'Lock Sleeve Interference Rear Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveInterferenceFront_dia = {
	name: 'lockSleeveInterferenceFront_dia',
	label: 'Lock Sleeve Interference Front Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreRear_dia = {
	name: 'bearingHousingBoreRear_dia',
	label: 'Bearing Housing Bore Rear Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingHousingBoreFront_dia = {
	name: 'bearingHousingBoreFront_dia',
	label: 'Bearing Housing Bore Front Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODRear_dia = {
	name: 'bearingShaftODRear_dia',
	label: 'Bearing Shaft OD Rear Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const bearingShaftODFront_dia = {
	name: 'bearingShaftODFront_dia',
	label: 'Bearing Shaft OD Front Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const lockSleeveSquareness_dia = {
	name: 'lockSleeveSquareness_dia',
	label: 'Lock Sleeve Squareness Diameter',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const InProcessInspectionComponents = {
	bearingShaftODFront_pv,
	bearingShaftODFront_result,
	bearingShaftODFront_remark,
	bearingShaftODFront_afterRework,
	bearingShaftODRear_pv,
	bearingShaftODRear_result,
	bearingShaftODRear_remark,
	bearingShaftODRear_afterRework,
	bearingHousingBoreFront_pv,
	bearingHousingBoreFront_result,
	bearingHousingBoreFront_remark,
	bearingHousingBoreFront_afterRework,
	bearingHousingBoreRear_pv,
	bearingHousingBoreRear_result,
	bearingHousingBoreRear_remark,
	bearingHousingBoreRear_afterRework,
	lockSleeveInterferenceFront_pv,
	lockSleeveInterferenceFront_result,
	lockSleeveInterferenceFront_remark,
	lockSleeveInterferenceFront_afterRework,
	lockSleeveInterferenceRear_pv,
	lockSleeveInterferenceRear_result,
	lockSleeveInterferenceRear_remark,
	lockSleeveInterferenceRear_afterRework,
	distanceSleeveClearance_pv,
	distanceSleeveClearance_result,
	distanceSleeveClearance_remark,
	distanceSleeveClearance_afterRework,
	distanceSleeveParallelity_pv,
	distanceSleeveParallelity_result,
	distanceSleeveParallelity_remark,
	distanceSleeveParallelity_afterRework,
	lockSleeveSquareness_pv,
	lockSleeveSquareness_result,
	lockSleeveSquareness_remark,
	lockSleeveSquareness_afterRework,
	runOutBearingODFront_pv,
	runOutBearingODFront_result,
	runOutBearingODFront_remark,
	runOutBearingODFront_afterRework,
	runOutBearingODRear_pv,
	runOutBearingODRear_result,
	runOutBearingODRear_remark,
	runOutBearingODRear_afterRework,
	housingConcentricity_pv,
	housingConcentricity_result,
	housingConcentricity_remark,
	housingConcentricity_afterRework,
	roOnPulleyDia_pv,
	roOnPulleyDia_result,
	roOnPulleyDia_remark,
	roOnPulleyDia_afterRework,
	roOnBearingRestingFace_pv,
	roOnBearingRestingFace_result,
	roOnBearingRestingFace_remark,
	roOnBearingRestingFace_afterRework,
	drawBarClampingForce_pv,
	drawBarClampingForce_result,
	drawBarClampingForce_remark,
	drawBarClampingForce_afterRework,
	pulleyCouplingInterferenceFront_pv,
	pulleyCouplingInterferenceFront_result,
	pulleyCouplingInterferenceFront_remark,
	pulleyCouplingInterferenceFront_afterRework,
	pulleyCouplingInterferenceRear_pv,
	pulleyCouplingInterferenceRear_result,
	pulleyCouplingInterferenceRear_remark,
	pulleyCouplingInterferenceRear_afterRework,
	shaftTotalHeight_pv,
	shaftTotalHeight_result,
	shaftTotalHeight_remark,
	shaftTotalHeight_afterRework,
	roOnShaftFrontSpace_pv,
	roOnShaftFrontSpace_result,
	roOnShaftFrontSpace_remark,
	roOnShaftFrontSpace_afterRework,
	rotorInterference_pv,
	rotorInterference_result,
	rotorInterference_remark,
	rotorInterference_afterRework,
	shaftBalancingValuesPlaneOne,
	shaftBalancingValuesPlaneTwo,
	shaftBalancingValuesRemark,
	distanceSleeveParallelity_dia,
	distanceSleeveClearance_dia,
	lockSleeveInterferenceRear_dia,
	lockSleeveInterferenceFront_dia,
	bearingHousingBoreRear_dia,
	bearingHousingBoreFront_dia,
	bearingShaftODRear_dia,
	bearingShaftODFront_dia,
	lockSleeveSquareness_dia,
};
