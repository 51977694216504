import {
	r_uv_br,
	r_uv_ar,
	r_vw_br,
	r_vw_ar,
	r_wu_br,
	r_we_ar,
	ind_uv_br,
	ind_uv_ar,
	ind_vw_br,
	ind_vw_ar,
	ind_wu_br,
	ind_we_ar,
	ins_pb_br,
	ins_pb_ar,
	ins_pt_br,
	ins_pt_ar,
	ins_bt_br,
	ins_bt_ar,
	cdc_cwt_br,
	cdc_cwt_ar,
	cdc_dc_br,
	cdc_dc_ar,
	cdc_cwot_br,
	cdc_cwot_ar,
	twc_KTY_br,
	twc_KTY_ar,
	twc_PTC_br,
	twc_PTC_ar,
	twc_NTC_br,
	twc_NTC_ar,
	spindleVoltage,
	spindlePower,
	spindleMaxFrequency,
	remark,
} from './ElectricalTestComponents';

export const ElectricalTestReport = [
	{
		groupHeader: 'Resistance Check',
		components: [r_uv_ar, r_vw_br, r_vw_ar, r_wu_br, r_we_ar],
	},
	{
		groupHeader: 'Inductance Check',
		components: [
			ind_uv_br,
			ind_uv_ar,
			ind_vw_br,
			ind_vw_ar,
			ind_wu_br,
			ind_we_ar,
		],
	},
	{
		groupHeader: 'Insulation Check',
		components: [
			ins_pb_br,
			ins_pb_ar,
			ins_pt_br,
			ins_pt_ar,
			ins_bt_br,
			ins_bt_ar,
		],
	},
	{
		groupHeader: 'Clamp - De_Clamp Sensor',
		components: [
			cdc_cwt_br,
			cdc_cwt_ar,
			cdc_dc_br,
			cdc_dc_ar,
			cdc_cwot_br,
			cdc_cwot_ar,
		],
	},
	{
		groupHeader: 'Thermistor and Wire Condition',
		components: [
			twc_KTY_br,
			twc_KTY_ar,
			twc_PTC_br,
			twc_PTC_ar,
			twc_NTC_br,
			twc_NTC_ar,
		],
	},
	{
		groupHeader: 'Parameters',
		components: [spindleVoltage, spindlePower, spindleMaxFrequency, remark],
	},
];
