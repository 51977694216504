export const r_uv_br = {
	name: 'r_uv_br',
	label: 'Resistance U-V Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const r_uv_ar = {
	name: 'r_uv_ar',
	label: 'Resistance U-V After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const r_vw_br = {
	name: 'r_vw_br',
	label: 'Resistance V-W Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const r_vw_ar = {
	name: 'r_vw_ar',
	label: 'Resistance V-W After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const r_wu_br = {
	name: 'r_wu_br',
	label: 'Resistance W-U Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const r_we_ar = {
	name: 'r_we_ar',
	label: 'Resistance W-E After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ind_uv_br = {
	name: 'ind_uv_br',
	label: 'Inductance U-V Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ind_uv_ar = {
	name: 'ind_uv_ar',
	label: 'Inductance U-V After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ind_vw_br = {
	name: 'ind_vw_br',
	label: 'Inductance V-W Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ind_vw_ar = {
	name: 'ind_vw_ar',
	label: 'Inductance V-W After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ind_wu_br = {
	name: 'ind_wu_br',
	label: 'Inductance W-U Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ind_we_ar = {
	name: 'ind_we_ar',
	label: 'Inductance W-E After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ins_pb_br = {
	name: 'ins_pb_br',
	label: 'Insulation Phase to Body Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ins_pb_ar = {
	name: 'ins_pb_ar',
	label: 'Insulation Phase to Body After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ins_pt_br = {
	name: 'ins_pt_br',
	label: 'Insulation Phase to Terminal Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ins_pt_ar = {
	name: 'ins_pt_ar',
	label: 'Insulation Phase to Terminal After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ins_bt_br = {
	name: 'ins_bt_br',
	label: 'Insulation Between Terminals Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const ins_bt_ar = {
	name: 'ins_bt_ar',
	label: 'Insulation Between Terminals After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const cdc_cwt_br = {
	name: 'cdc_cwt_br',
	label: 'Capacitance CWT Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const cdc_cwt_ar = {
	name: 'cdc_cwt_ar',
	label: 'Capacitance CWT After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const cdc_dc_br = {
	name: 'cdc_dc_br',
	label: 'Capacitance DC Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const cdc_dc_ar = {
	name: 'cdc_dc_ar',
	label: 'Capacitance DC After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const cdc_cwot_br = {
	name: 'cdc_cwot_br',
	label: 'Capacitance CWOT Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const cdc_cwot_ar = {
	name: 'cdc_cwot_ar',
	label: 'Capacitance CWOT After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const twc_KTY_br = {
	name: 'twc_KTY_br',
	label: 'Temperature Sensor KTY Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const twc_KTY_ar = {
	name: 'twc_KTY_ar',
	label: 'Temperature Sensor KTY After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const twc_PTC_br = {
	name: 'twc_PTC_br',
	label: 'Temperature Sensor PTC Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const twc_PTC_ar = {
	name: 'twc_PTC_ar',
	label: 'Temperature Sensor PTC After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const twc_NTC_br = {
	name: 'twc_NTC_br',
	label: 'Temperature Sensor NTC Before Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const twc_NTC_ar = {
	name: 'twc_NTC_ar',
	label: 'Temperature Sensor NTC After Repair',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const spindleVoltage = {
	name: 'spindleVoltage',
	label: 'Spindle Voltage',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const spindlePower = {
	name: 'spindlePower',
	label: 'Spindle Power',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const spindleMaxFrequency = {
	name: 'spindleMaxFrequency',
	label: 'Spindle Max Frequency',
	type: 'text',
	validation: {
		type: 'string',
		required: true,
	},
};

export const remark = {
	name: 'remark',
	label: 'Remark',
	type: 'longtext',
	validation: {
		type: 'string',
		optional: true,
		maxLength: 1000,
	},
};

export const ElectricalTestComponents = {
	r_uv_br,
	r_uv_ar,
	r_vw_br,
	r_vw_ar,
	r_wu_br,
	r_we_ar,
	ind_uv_br,
	ind_uv_ar,
	ind_vw_br,
	ind_vw_ar,
	ind_wu_br,
	ind_we_ar,
	ins_pb_br,
	ins_pb_ar,
	ins_pt_br,
	ins_pt_ar,
	ins_bt_br,
	ins_bt_ar,
	cdc_cwt_br,
	cdc_cwt_ar,
	cdc_dc_br,
	cdc_dc_ar,
	cdc_cwot_br,
	cdc_cwot_ar,
	twc_KTY_br,
	twc_KTY_ar,
	twc_PTC_br,
	twc_PTC_ar,
	twc_NTC_br,
	twc_NTC_ar,
	spindleVoltage,
	spindlePower,
	spindleMaxFrequency,
	remark,
};
