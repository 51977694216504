import { Typography } from '@mui/material';
import OrderAction from './components/OrderAction';
import OrdersTable from './components/OrdersTable';
import './home.css';
import { Link } from 'react-router-dom';
import SectionHeader from '../shared/typography/SectionHeader';

function Home() {
	return (
		<div>
			<div className='childCard'>
				<SectionHeader s={{ marginBottom: '24px' }}>
					Welcome to SpinTrack!
				</SectionHeader>
				<OrderAction />
			</div>
			<div className='childCard'>
				<SectionHeader>Recent Orders</SectionHeader>
				<OrdersTable />
			</div>
			<div className='childCard'>
				<SectionHeader>Quick Actions</SectionHeader>
				<Typography gutterBottom>
					<Link to='/create-order'>Create New Order</Link>
				</Typography>
				<Typography gutterBottom>
					<Link to='/order-dashboard'>Current Order</Link>
				</Typography>
				<Typography gutterBottom>
					<Link to='/all-orders'>Browse all orders</Link>
				</Typography>
				<Typography gutterBottom>
					<Link
						href='https://www.setco.com/'
						target='_blank'
						rel='noopener noreferrer'
					>
						Setco Spindles
					</Link>
				</Typography>
			</div>
		</div>
	);
}

export default Home;
