import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import ReportField from '../../report/components/ReportField';
import './renders.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { reports } from '../../../config/reports/reports';
import useHelperFunctions from '../../../helpers/functions';

function GroupedReportFields({ handleClick }) {
	const { location } = useHelperFunctions();
	const pathSegments = location.pathname.split('/').filter(Boolean);
	const firstSegment = pathSegments[0];

	const [expandedGroups, setExpandedGroups] = useState(() => {
		const initialState = {};
		reports[firstSegment].forEach((_, index) => {
			initialState[index] = true;
		});
		return initialState;
	});

	const toggleGroup = (index) => {
		setExpandedGroups((prev) => ({
			...prev,
			[index]: !prev[index],
		}));
	};

	return (
		<Box>
			{reports[firstSegment].map((group, index) => (
				<div className='reportFieldGroupContainer' key={index}>
					<div className='reportFieldGroup'>
						<Typography variant='h6'>{group.groupHeader}</Typography>
						<IconButton onClick={() => toggleGroup(index)}>
							{expandedGroups[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</IconButton>
					</div>
					<Collapse in={expandedGroups[index]} timeout='auto' unmountOnExit>
						<div className='reportFieldGroups'>
							{(group.components || group.component || []).map(
								(component, idx) => (
									<ReportField
										key={idx}
										handleClick={handleClick}
										component={component}
									/>
								)
							)}
						</div>
					</Collapse>
				</div>
			))}
		</Box>
	);
}

export default GroupedReportFields;
