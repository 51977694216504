import {
	Button,
	Card,
	CardActionArea,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
	Paper,
} from '@mui/material';
import loadOrder from '../../../assets/staticImages/load-orders.png';
import newOrder from '../../../assets/staticImages/new-order.png';
import useHelperFunctions from '../../../helpers/functions';

function OrderAction() {
	const { navigate, showOutlet, setCreatedNewOrderFalse, hideOutlet } =
		useHelperFunctions();

	function handleClick(url) {
		console.log('clicked handle click');
		navigate(url);
		showOutlet();
		setCreatedNewOrderFalse();
	}

	function handleLoadOrders() {
		hideOutlet();
		navigate('/all-orders');
	}

	return (
		<Paper
			elevation={0}
			sx={{
				display: 'flex',
				gap: 4,
				flexWrap: 'wrap',
			}}
		>
			<Card
				sx={{ maxWidth: 300, margin: 0, border: 1, borderColor: 'lightgray' }}
				onClick={() => handleClick('/create-order')}
			>
				<CardActionArea>
					<CardMedia
						component='img'
						height='140'
						image={newOrder}
						alt='green iguana'
					/>
					<CardContent>
						<Typography gutterBottom variant='h5' component='div'>
							Register New Order
						</Typography>
						<Typography variant='body2' sx={{ color: 'text.secondary' }}>
							Click here to register a new order by providing a unique inward
							number. Please ensure that the customer is already added to the
							registry.
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button size='small'>Customer List</Button>
					<Button size='small'>Add Customer</Button>
				</CardActions>
			</Card>
			<Card
				sx={{ maxWidth: 300, border: 1, borderColor: 'lightgray' }}
				onClick={handleLoadOrders}
			>
				<CardActionArea>
					<CardMedia
						component='img'
						height='140'
						image={loadOrder}
						alt='green iguana'
					/>
					<CardContent>
						<Typography gutterBottom variant='h5' component='div'>
							Load Existing Order
						</Typography>
						<Typography variant='body2' sx={{ color: 'text.secondary' }}>
							You can load a previous order by scanning the QR code present on
							the spindle or by simply entering the inward number. You can also
							browse the order database.
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button size='small'>Browse All Orders</Button>
					<Button size='small'>Scan QR Code</Button>
				</CardActions>
			</Card>
		</Paper>
	);
}

export default OrderAction;
