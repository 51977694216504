import {
	Settings,
	Person,
	Description,
	Assignment,
	AddBox,
	FolderOpen,
} from '@mui/icons-material';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';

export const menuItems = [
	{
		label: 'Current Order',
		path: '/order-dashboard',
		icon: <QrCode2OutlinedIcon />,
	},
	{
		label: 'Order',
		icon: <Assignment />,
		children: [
			{ label: 'Create New Order', path: '/create-order', icon: <AddBox /> },
			{ label: 'Browse Orders', path: '/all-orders', icon: <FolderOpen /> },
		],
	},
	// {
	// 	label: 'Reports',
	// 	icon: <Description />,
	// 	children: [
	// 		{ label: 'Edit Report', path: '/report-gallery', icon: <Assignment /> }, // You might want to replace `123` dynamically
	// 		{
	// 			label: 'Download Report',
	// 			path: '/orderDashboard',
	// 			icon: <FolderOpen />,
	// 		},
	// 	],
	// },

	{
		label: 'Settings',
		path: '/settings',
		icon: <Settings />,
	},
	{
		label: 'Profile',
		path: '/user',
		icon: <Person />,
	},
];

/**
 * Order
 * - create new order -> navigates to /create-order
 * - browse orders -> navigates to /all-orders
 * Reports
 * - edit report -> navigates to /:orderId || 123
 * - download report -> navigates to /orderDashboard (show available reports, as well as media and communication details)
 * Settings -> navigates to /settings
 * Profile -> navigates to /user
 */
