import React from 'react';
import { Controller, get } from 'react-hook-form';

import {
	TextField,
	Checkbox,
	FormControlLabel,
	FormControl,
	FormGroup,
	RadioGroup,
	Radio,
	Select,
	MenuItem,
	Typography,
	TextareaAutosize,
	Box,
} from '@mui/material';
import FiledLabel from './components/FiledLabel';
import FieldContainer from './components/FieldContainer';

function DynamicFormRenderer({
	config,
	control,
	register,
	errors,
	value,
	parentName = '',
}) {
	// Normalize config to always be an array
	const fields = Array.isArray(config) ? config : [config];

	return fields.map((field) => {
		const fieldName = parentName ? `${parentName}.${field.name}` : field.name;
		const error = get(errors, fieldName);

		switch (field.type) {
			case 'text':
				return (
					<Box key={fieldName} sx={{ mb: 2 }}>
						<Controller
							name={fieldName}
							control={control}
							defaultValue={value ? value : ''}
							render={({ field: controllerField }) => (
								<FieldContainer>
									<FiledLabel>{field.label}</FiledLabel>
									<TextField
										{...controllerField}
										label={field.label}
										variant='outlined'
										fullWidth
										required={field.required}
										error={!!error}
										helperText={error?.message}
									/>
								</FieldContainer>
							)}
						/>
					</Box>
				);
			case 'number':
			case 'date':
				return (
					<Box key={fieldName} sx={{ mb: 2 }}>
						<Controller
							name={fieldName}
							control={control}
							defaultValue={value ? value : ''}
							render={({ field: controllerField }) => (
								<FieldContainer>
									<FiledLabel>{field.label}</FiledLabel>
									<TextField
										{...controllerField}
										type={field.type}
										label={field.label}
										variant='outlined'
										fullWidth
										required={field.required}
										error={!!error}
										helperText={error?.message}
										InputLabelProps={
											field.type === 'date' ? { shrink: true } : undefined
										}
									/>
								</FieldContainer>
							)}
						/>
					</Box>
				);
			case 'longtext':
				return (
					<Box key={fieldName} sx={{ mb: 2 }}>
						<Controller
							name={fieldName}
							control={control}
							defaultValue={value ? value : ''}
							render={({ field: controllerField }) => (
								<FieldContainer>
									<FiledLabel>{field.label}</FiledLabel>
									<TextareaAutosize
										{...controllerField}
										minRows={3}
										placeholder={field.label}
										style={{ width: '100%', padding: '8px' }}
									/>
								</FieldContainer>
							)}
						/>
					</Box>
				);
			case 'checkbox':
				return (
					<FieldContainer>
						<FormControl key={fieldName} component='fieldset'>
							<FiledLabel>{field.label}</FiledLabel>
							<FormGroup row>
								{field.options.map((option) => (
									<FormControlLabel
										key={option.value}
										control={
											<Controller
												name={fieldName}
												control={control}
												defaultValue={[]}
												render={({ field: { onChange, value } }) => (
													<Checkbox
														checked={value.includes(option.value)}
														onChange={() => {
															const newValue = value.includes(option.value)
																? value.filter((v) => v !== option.value)
																: [...value, option.value];
															onChange(newValue);
														}}
													/>
												)}
											/>
										}
										label={option.label}
									/>
								))}
							</FormGroup>
						</FormControl>
					</FieldContainer>
				);
			case 'dropdown':
				return (
					<Box key={fieldName} sx={{ mb: 2 }}>
						<FieldContainer>
							<FormControl fullWidth>
								<FiledLabel>{field.label}</FiledLabel>
								<Controller
									name={fieldName}
									control={control}
									defaultValue=''
									render={({ field: controllerField }) => (
										<Select {...controllerField} displayEmpty>
											<MenuItem value='' disabled>
												Select an option
											</MenuItem>
											{field.options.map((option) => (
												<MenuItem key={option.value} value={option.value}>
													{option.label}
												</MenuItem>
											))}
										</Select>
									)}
								/>
							</FormControl>
						</FieldContainer>
					</Box>
				);
			case 'radio':
				return (
					<FieldContainer>
						<FormControl key={fieldName} component='fieldset' sx={{ mb: 2 }}>
							<FiledLabel>{field.label}</FiledLabel>
							<Controller
								name={fieldName}
								control={control}
								defaultValue=''
								render={({ field: controllerField }) => (
									<RadioGroup {...controllerField} row>
										{field.options.map((option) => (
											<FormControlLabel
												key={option.value}
												value={option.value}
												control={<Radio />}
												label={option.label}
											/>
										))}
									</RadioGroup>
								)}
							/>
						</FormControl>
					</FieldContainer>
				);
			case 'file':
				return (
					<Box key={fieldName}>
						<FieldContainer>
							<FiledLabel>{field.label}</FiledLabel>
							<Controller
								name={fieldName}
								control={control}
								defaultValue={null}
								render={({ field: { onChange, value } }) => (
									<>
										<input
											type='file'
											multiple
											onChange={(e) => {
												const files = Array.from(e.target.files);
												onChange(files);
											}}
										/>
										<Box sx={{ display: 'flex', flexWrap: 'wrap', mt: 1 }}>
											{value &&
												value.map((file, index) => (
													<img
														key={index}
														src={URL.createObjectURL(file)}
														alt='preview'
														style={{ width: 100, height: 100, marginRight: 8 }}
													/>
												))}
										</Box>
									</>
								)}
							/>
						</FieldContainer>
					</Box>
				);
			case 'group':
				return (
					<Box key={fieldName}>
						<Typography
							variant='h6'
							mb={2}
							sx={{ fontWeight: '600' }}
							gutterBottom
						>
							{field.label}
						</Typography>
						<DynamicFormRenderer
							config={field.subfields}
							control={control}
							register={register}
							errors={errors}
							parentName={fieldName}
						/>
					</Box>
				);
			default:
				return null;
		}
	});
}

export default DynamicFormRenderer;
