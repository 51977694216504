const options = {
	true: 'Yes',
	false: 'No',
	na: 'Not Applicable',
	woodenBox: 'Wooden Box',
	cartonBox: 'Carton Box',
	withoutBox: 'Without Box',
	missing: 'Missing',
	ok: 'Ok',
	damage: 'Damage',
	rework: 'Rework',
	replace: 'Replace',
	IncomingAlert: 'Incoming Alert',
	CheckSheet: 'Check Sheet',
	DamageReport: 'Damage Report',
	InProcessInspectionReport: 'In Process Inspection Report',
	OldBearingReport: 'Old Bearing Report',
	NewBearingReport: 'New Bearing Report',
	ElectricalTestReport: 'Electrical Test Report',
	FinalInspectionReport: 'Final Inspection Report',
	TestingAndBalancingReport: 'Testing and Balancing Report',
};

const optionsArray = [
	'true',
	'false',
	'na',
	'ok',
	'damage',
	'rework',
	'replace',
	'missing',
	'woodenBox',
	'cartonBox',
	'withoutBox',
	'IncomingAlert',
	'CheckSheet',
	'DamageReport',
	'InProcessInspectionReport',
	'OldBearingReport',
	'NewBearingReport',
	'ElectricalTestReport',
	'FinalInspectionReport',
	'TestingAndBalancingReport',
	'missing',
];

export function lookup(v) {
	let e = optionsArray.includes(v.toString());
	if (!e) {
		return v;
	}
	return options[v.toString()];
}
