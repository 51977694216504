import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import './navbar.css';
import setco from '../../../assets/staticImages/setco.png';
import useHelperFunctions from '../../../helpers/functions';

function Navbar() {
	const {
		navigate,
		isSidebarOpen,
		showSidebar,
		hideSidebar,
		hideOutlet,
		isTabletOrBelow,
	} = useHelperFunctions();

	function handleLogoClick() {
		navigate('/');
		hideOutlet();
	}

	return (
		<div className='navbar'>
			{isTabletOrBelow && (
				<div className='menuIcon'>
					{!isSidebarOpen && <MenuIcon onClick={showSidebar} />}
					{isSidebarOpen && <MenuOpenIcon onClick={hideSidebar} />}
				</div>
			)}
			<img
				className='setcoLogo'
				src={setco}
				alt='Setco India'
				onClick={handleLogoClick}
			/>
		</div>
	);
}

export default Navbar;
