import React, { useState } from 'react';
import { Snackbar, Alert, Link } from '@mui/material';

export default function NotificationPopup({
	type,
	open,
	handleClose,
	inwardNumber,
}) {
	const getMessage = () => {
		switch (type) {
			case 'success':
				return 'Order registered successfully!';
			case 'error':
				return 'Something went wrong. Please try again.';
			case 'warning':
				return (
					<>
						Order with that inward number already exists.{' '}
						<Link href={`http://localhost/${inwardNumber}`} target='_blank'>
							View Order
						</Link>
					</>
				);
			default:
				return '';
		}
	};

	return (
		<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
			<Alert
				onClose={handleClose}
				severity={type}
				sx={{
					width: '345px',
					position: 'fixed',
					top: { xs: 64, md: 'auto' }, // Only apply top on smaller screens
					right: { xs: 0, md: 'auto' }, // Only apply right on smaller screens
					bottom: { xs: 'auto', md: 64 }, // Apply bottom on md and above
					left: { xs: 'auto', md: 272 },
				}}
			>
				{getMessage()}
			</Alert>
		</Snackbar>
	);
}
