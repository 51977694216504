import {
	boxType,
	cylinder,
	proxySwitch,
	tenon,
	pulley,
	drawbar,
	speedSensor,
	spindleCondition,
	bearingNoise,
	others,
} from './CheckSheetComponents';

export const CheckSheetReport = [
	{
		groupHeader: 'Check Sheet',
		components: [
			boxType,
			cylinder,
			proxySwitch,
			tenon,
			pulley,
			drawbar,
			speedSensor,
			spindleCondition,
			bearingNoise,
			others,
		],
	},
];
