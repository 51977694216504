import React from 'react';
import useHelperFunctions from '../../helpers/functions';
import MainForm from '../../experiments/DynaForm';

function FieldForm() {
	const { location } = useHelperFunctions();
	const state = location.state ? location.state : '';

	return (
		<div>
			<MainForm formConfig={state} />
		</div>
	);
}

export default FieldForm;
