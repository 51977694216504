import { Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

function SectionHeader({ s, children, mark = true }) {
	return (
		<Typography color='primary' variant='h5' mb={2} sx={s ? s : {}}>
			<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
				{mark && <KeyboardDoubleArrowRightIcon sx={{ fontSize: '16px' }} />}
				<div>{children}</div>
			</div>
		</Typography>
	);
}

export default SectionHeader;
