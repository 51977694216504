import spintrack from '../../../assets/staticImages/spintrack.png';

function Footer() {
	return (
		<footer>
			<img src={spintrack} alt='' className='footerLogo' />
			<p
				style={{
					textAlign: 'center',
					fontSize: '12px',
					fontStyle: 'italic',
					color: '#333333',
				}}
			>
				Precise Tracking, Perfect Repairs
			</p>
		</footer>
	);
}

export default Footer;
