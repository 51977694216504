import { createTheme } from '@mui/material';

export const theme = createTheme({
	typography: {
		fontFamily: '"Roboto Flex", "Helvetica", "Arial", sans-serif',
		h5: {
			color: 'primary.main', // Sets h5 color to the primary color
		},
		h6: {
			color: '#333333', // Sets h6 color to #333333
			fontWeight: 'normal', // Sets h6 font weight to normal
		},
	},
	palette: {
		primary: {
			main: '#384B70',
		},
		secondary: {
			main: '#B8001F',
		},
		plainBg: '#FCFAEE',
	},
});
