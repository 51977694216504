import React, { useEffect, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	TextField,
	TableSortLabel,
	Tooltip,
	Button,
	InputAdornment,
	Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useUIStore from '../../../stores/useUiStore';
import SectionHeader from '../../shared/typography/SectionHeader';
import useHelperFunctions from '../../../helpers/functions';
import SearchIcon from '@mui/icons-material/Search';
import { useFetchData } from '../../../api/axios';

function AllOrders() {
	const [searchTerm, setSearchTerm] = useState('');
	const [sortColumn, setSortColumn] = useState('srNumber');
	const [sortDirection, setSortDirection] = useState('desc'); // Default to descending
	const [tableData, setTableData] = useState([]);

	const { isTabletOrBelow } = useHelperFunctions();

	const { data, error, isLoading, isFetching } = useFetchData({
		queryKey: ['orders'],
		url: '/order',
		options: {
			refetchOnWindowFocus: true,
			refetchOnReconnect: true,
			refetchOnMount: 'always',
			staleTime: 30 * 60 * 1000,
		},
	});

	useEffect(() => {
		console.log('Fetched data:', data);
		setTableData(data?.data || []); // Default to empty array if no data
	}, [data]);

	const handleSearch = (event) =>
		setSearchTerm(event.target.value.toLowerCase());

	const handleSort = (column) => {
		const isDesc = sortColumn === column && sortDirection === 'desc';
		setSortDirection(isDesc ? 'asc' : 'desc');
		setSortColumn(column);
	};

	const filteredData = tableData
		?.filter((row) => row.inwardNumber.toLowerCase().includes(searchTerm))
		.sort((a, b) => {
			const order = sortDirection === 'asc' ? 1 : -1;
			if (sortColumn === 'registrationDate') {
				return (new Date(a.createdAt) - new Date(b.createdAt)) * order;
			}
			return a[sortColumn] > b[sortColumn] ? order : -order;
		});

	const getStatusColor = (status) => {
		switch (status) {
			case 1:
				return 'red';
			case 2:
				return 'orange';
			case 3:
				return 'blue';
			case 4:
				return 'green';
			default:
				return 'black';
		}
	};

	const { clearOrderIndex, setOrderIndex } = useHelperFunctions();
	const navigate = useNavigate();
	const hideOutlet = useUIStore((state) => state.hideOutlet);

	const handleOpenOrder = (row) => {
		clearOrderIndex();
		setOrderIndex(row);
		navigate(`/${row.orderId}`);
		hideOutlet();
	};

	return (
		<>
			<SectionHeader>Search amongst all orders</SectionHeader>

			<Paper elevation={0} sx={{ maxWidth: '932px', overflow: 'hidden' }}>
				<TextField
					label='Search by Client Name or Inward Number'
					variant='outlined'
					fullWidth
					margin='normal'
					onChange={handleSearch}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
				<TableContainer sx={{ maxHeight: isTabletOrBelow ? 440 : 550 }}>
					<Table stickyHeader>
						<TableHead>
							<TableRow>
								{[
									'srNumber',
									'inwardNumber',
									'registrationDate',
									'stage',
									'status',
									'action',
								].map((column) => (
									<TableCell key={column}>
										<TableSortLabel
											active={sortColumn === column}
											direction={sortColumn === column ? sortDirection : 'asc'}
											onClick={() => handleSort(column)}
										>
											{column
												.replace(/([A-Z])/g, ' $1')
												.replace(/^./, (str) => str.toUpperCase())}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredData?.map((row, index) => (
								<Tooltip title='Click to open' arrow key={row.srNumber}>
									<TableRow
										sx={{
											'&:hover': {
												backgroundColor: 'rgba(0, 0, 0, 0.1)',
												cursor: 'pointer',
											},
										}}
										onClick={() => handleOpenOrder(row)}
									>
										<TableCell>{index + 1}</TableCell>
										<TableCell>{row.inwardNumber}</TableCell>
										<TableCell>
											{new Date(row.createdAt).toLocaleDateString('en-GB', {
												day: '2-digit',
												month: 'short',
												year: 'numeric',
											})}
										</TableCell>
										<TableCell>{row.stage}</TableCell>
										<TableCell style={{ color: getStatusColor(row.status) }}>
											{row.status}
										</TableCell>
										<TableCell>
											<Button size='small'>Open</Button>
										</TableCell>
									</TableRow>
								</Tooltip>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
}

export default AllOrders;
