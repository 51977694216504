import { useEffect, useRef } from 'react';
import AppRoutes from './appRoutes.js/AppRoutes';
import useUIStore from './stores/useUiStore';

function App() {
	const showOutlet = useUIStore((state) => state.isOutletOpen);
	const componentRef = useRef(null);

	// Scroll to top when `showOutlet` changes
	useEffect(() => {
		if (showOutlet && componentRef.current) {
			componentRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		}
	}, [showOutlet]);
	return (
		<div ref={componentRef}>
			<AppRoutes />;
		</div>
	);
}

export default App;
