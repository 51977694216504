import { Typography } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import React from 'react';
import { useFetchData } from '../../../api/axios';
import useHelperFunctions from '../../../helpers/functions';
import { lookup } from '../../../config/lookup/lookup';
import LoaderPulseLoader from '../../shared/Loader/PulseLoader';

function ReportField({ handleClick, component = {} }) {
	const { location, currentOrder } = useHelperFunctions();

	const pathSegments = location.pathname.split('/').filter(Boolean);
	const firstSegment = pathSegments[0];
	const secondSegment = pathSegments[1];

	const { data, error, isLoading, isFetching } = useFetchData({
		queryKey: [component.name],
		url: `/report/${firstSegment}/${secondSegment}/field?field=${encodeURIComponent(
			component.name
		)}&orderId=${encodeURIComponent(currentOrder.orderId)}`,
		options: {
			refetchOnWindowFocus: true,
			refetchOnReconnect: true,
			refetchOnMount: 'always',
			staleTime: 30 * 60 * 1000,
		},
	});

	let componentWithUrl = () => {
		let url = `report/${firstSegment}/${currentOrder[firstSegment]}`;
		component['url'] = url;
		component['method'] = 'PATCH';
		return component;
	};

	console.log('data', data, 'component name', component.name);

	return (
		<div
			className='fieldWrapper'
			onClick={() => handleClick(component.name, componentWithUrl())}
		>
			<EditNoteIcon className='editIcon' />
			<Typography variant='body1' style={{ fontSize: '18px' }} gutterBottom>
				<div style={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
					<p className='fieldLabel'>
						{component.label}
						{'  '}{' '}
					</p>
					<p
						className={
							data
								? data['data'] === 'field not updated'
									? 'emptyValue'
									: 'fieldValue'
								: 'fieldValue'
						}
					>
						{data ? lookup(data['data']) : <LoaderPulseLoader />}
						{/* {data ? data['data'].toString() : 'loading'} */}
					</p>
				</div>
			</Typography>
		</div>
	);
}

export default ReportField;
