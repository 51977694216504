import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { devtools } from 'zustand/middleware';

const useUIStore = create(
	devtools(
		persist((set) => ({
			// Initial state
			isSidebarOpen: false,
			isOutletOpen: false,
			createdNewOrder: false,
			orderIndex: {},

			// Actions to update state
			toggleSidebar: () =>
				set((state) => ({ isSidebarOpen: !state.isSidebarOpen })),
			toggleOutlet: () =>
				set((state) => ({ isOutletOpen: !state.isOutletOpen })),

			showOutlet: () => set({ isOutletOpen: true }),
			hideOutlet: () => set({ isOutletOpen: false }),

			showSidebar: () => set({ isSidebarOpen: true }),
			hideSidebar: () => set({ isSidebarOpen: false }),

			setCreatedNewOrderTrue: () => set({ createdNewOrder: true }),
			setCreatedNewOrderFalse: () => set({ createdNewOrder: false }),

			setOrderIndex: (orderData) => set({ orderIndex: orderData }),
			clearOrderIndex: () => {
				set({ orderIndex: {} });
			},
		}))
	)
);

export default useUIStore;
