export const bearing = {
	name: 'bearing',
	label: 'Bearing',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const shaftTaper = {
	name: 'shaftTaper',
	label: 'Shaft Taper',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const shaftOd = {
	name: 'shaftOd',
	label: 'Shaft OD',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const mechanicalSeal = {
	name: 'mechanicalSeal',
	label: 'Mechanical Seal',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const pulley = {
	name: 'pulley',
	label: 'Pulley',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const encoder = {
	name: 'encoder',
	label: 'Encoder',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const rotaryUnion = {
	name: 'rotaryUnion',
	label: 'Rotary Union',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const spacerDistanceSleeve = {
	name: 'spacerDistanceSleeve',
	label: 'Spacer Distance Sleeve',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const tenon = {
	name: 'tenon',
	label: 'Tenon',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const oRings = {
	name: 'oRings',
	label: 'O-Rings',
	type: 'dropdown',
	options: [
		{ value: 'ok', label: 'OK' },
		{ value: 'damaged', label: 'Damaged' },
		{ value: 'rework', label: 'Rework' },
		{ value: 'replace', label: 'Replace' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['ok', 'damaged', 'rework', 'replace'],
	},
};

export const drawbarCollets = {
	name: 'drawbarCollets',
	label: 'Drawbar Collets',
	type: 'dropdown',
	options: [
		{ value: 'collets', label: 'Collets' },
		{ value: 'fingers', label: 'Fingers' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['collets', 'fingers'],
	},
};

export const drawbarSprings = {
	name: 'drawbarSprings',
	label: 'Drawbar Springs',
	type: 'dropdown',
	options: [
		{ value: 'disc', label: 'Disc' },
		{ value: 'helical', label: 'Helical' },
	],
	validation: {
		type: 'string',
		required: true,
		oneOf: ['disc', 'helical'],
	},
};

export const DamageReportComponents = {
	bearing,
	shaftTaper,
	shaftOd,
	mechanicalSeal,
	pulley,
	encoder,
	rotaryUnion,
	spacerDistanceSleeve,
	tenon,
	oRings,
	drawbarCollets,
	drawbarSprings,
};
