import { Box } from '@mui/material';

function FieldContainer({ children }) {
	const boxStyle = {
		border: '1px solid lightgray',
		padding: '24px',
		paddingBottom: '40px',
		borderRadius: '8px',
	};
	return (
		<Box mb={4} sx={boxStyle}>
			{children}
		</Box>
	);
}

export default FieldContainer;
