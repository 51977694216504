import AllOrders from '../components/home/components/AllOrders';
import CreateOrder from '../components/home/components/createOrder/CreateOrder';
import Home from '../components/home/Home';
import OrderPage from '../components/order/OrderPage';
import OrderDashboard from '../components/orderDashboard/OrderDashboard';
import FieldForm from '../components/report/FieldForm';
import ReportPage from '../components/report/ReportPage';
import SplitScreen from '../layout/SplitScreen';

export const routesConfig = [
	{
		path: '/',
		element: <Home />,
		layout: SplitScreen,
		children: [
			{
				path: 'create-order',
				element: <CreateOrder />,
			},
			{
				path: 'load-order',
				element: <CreateOrder />,
			},
		],
	},
	{
		path: '/all-orders',
		layout: SplitScreen,
		element: <AllOrders />,
	},
	{
		path: '/order-dashboard',
		layout: SplitScreen,
		element: <OrderDashboard />,
	},
	{
		path: '/settings',
		layout: SplitScreen,
		element: <h1>Work under progress</h1>,
	},
	{
		path: '/user',
		layout: SplitScreen,
		element: <h1>Work under progress</h1>,
	},
	{
		path: '/:orderId',
		element: <OrderPage />,
		layout: SplitScreen,
	},
	{
		path: '/:reportName/:reportId',
		element: <ReportPage />,
		layout: SplitScreen,
		children: [
			{
				path: ':fieldName',
				element: <FieldForm />,
			},
		],
	},
	{
		path: '/settings',
		element: <h1>Work under progress</h1>,
	},
	{
		path: '/settings',
		element: <h1>Work under progress</h1>,
	},
];
