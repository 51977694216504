import { TextField, Button, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import NotificationPopup from './NotificationPopup';
import { useMutateData } from '../../../../api/axios';
import useHelperFunctions from '../../../../helpers/functions';
import { useQueryClient } from '@tanstack/react-query';

function InputInward() {
	const [inputValue, setInputValue] = useState('');
	const [isTouched, setIsTouched] = useState(false);

	const { setOrderIndex, setCreatedNewOrderTrue } = useHelperFunctions();

	const queryClient = useQueryClient();

	const [popup, setPopup] = useState({
		open: false,
		type: '',
		inwardNumber: '',
	});

	const { mutate, isLoading, error, isSuccess } = useMutateData({
		method: 'POST',
		url: '/order',
	});

	const handleClose = () => setPopup({ ...popup, open: false });

	const handleInputChange = (event) => {
		if (!isTouched) {
			setIsTouched(true);
		}
		setInputValue(event.target.value);
	};

	const handleSubmit = () => {
		let inwardNumber = inputValue;
		console.log('submitting');
		mutate(
			{ inwardNumber },
			{
				onSuccess: (data) => {
					queryClient.invalidateQueries(['recentOrders']);
					console.log('Order created successfully:', data.data);
					setOrderIndex(data.data.data);
					setCreatedNewOrderTrue();
					setPopup({
						open: true,
						type: 'success',
						inwardNumber: inwardNumber,
					});
				},
				onError: (error) => {
					console.error('Error creating order:', error);
					setPopup({
						open: true,
						type: 'error',
						inwardNumber: inwardNumber,
					});
				},
			}
		);
	};

	return (
		<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
			<TextField
				label='Enter text'
				variant='outlined'
				value={inputValue}
				onChange={handleInputChange}
				error={!inputValue && isTouched} // Show error styling if empty
				helperText={
					!inputValue && isTouched ? 'This field cannot be empty' : ''
				}
				fullWidth
			/>

			<Button
				variant='outlined'
				disabled={!inputValue} // Disable button if input is empty
				onClick={handleSubmit}
			>
				Submit
			</Button>
			<NotificationPopup
				type={popup.type}
				open={popup.open}
				handleClose={handleClose}
				inwardNumber={inputValue}
			/>
		</Box>
	);
}

export default InputInward;
