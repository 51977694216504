import { Typography } from '@mui/material';

function FiledLabel({ children }) {
	return (
		<Typography variant='body1' mb={2} fontSize={'18px'} gutterBottom>
			{children}
		</Typography>
	);
}

export default FiledLabel;
