import { Route } from 'react-router-dom';

function createRoutes(routes) {
	return routes.map(({ path, element, layout: Layout, children }) => (
		<Route
			key={path}
			path={path}
			element={Layout ? <Layout>{element}</Layout> : element}
		>
			{children && createRoutes(children)}
		</Route>
	));
}

export default createRoutes;
