import { Outlet } from 'react-router-dom';
import './main.css';
import { useEffect, useRef, useState } from 'react';
import useUIStore from '../../../stores/useUiStore';

function MainArea({ children, isTabletOrBelow }) {
	// const [showOutlet, setShowOutlet] = useState(true);
	const showOutlet = useUIStore((state) => state.isOutletOpen);

	return (
		<div className='mainArea'>
			{showOutlet && (
				<div className='outletContainer'>
					<Outlet />
				</div>
			)}
			<div
				className='childContainer'
				style={{
					minWidth: isTabletOrBelow ? '100%' : showOutlet ? '' : '50%',
				}}
			>
				{children}
			</div>
		</div>
	);
}

export default MainArea;
