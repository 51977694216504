import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';

// const url = 'http://localhost:5000';
const url = `/`;
// const url = 'https://www.spintrack.in';

const axiosInstance = axios.create({
	baseURL: url, // Replace with your base URL
	timeout: 10000, // Timeout after 10 seconds
	headers: {
		'Content-Type': 'application/json',
	},
});

export const useFetchData = ({ queryKey, url, config = {}, options = {} }) => {
	const queryFn = async () => {
		const response = await axiosInstance.get(url, config);
		return response.data;
	};

	// Return the useQuery call
	return useQuery({
		queryKey,
		queryFn,
		...options,
	});
};

export const useMutateData = ({ method, url }) => {
	return useMutation({
		mutationFn: (data) => {
			return axiosInstance({
				method,
				url,
				data,
			});
		},
	});
};
