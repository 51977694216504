import { CheckSheetReport } from './checksheet/CheckSheetReport';
import { DamageReport } from './damageReport/DamageReport';
import { ElectricalTestReport } from './electricalTestReport/ElectricalTestReport';
import { FinalInspectionReport } from './FinalInspectionReport/FinalInspectionReport';
import { IncomingAlertReport } from './incomingAlert/incomingAlertReport';
import { InProcessInspectionReport } from './InProcessInspectionReport/InPorcessInspectionReport';
import { NewBearingReport } from './NewBearingReport/NewBearingReport';
import { OldBearingReport } from './OldBearingReport/OldBearingReport';
import { TestingAndBalancingReport } from './TestingAndBalancingReport/TestingAndBalancingReport';

export const reports = {
	CheckSheet: CheckSheetReport,
	DamageReport: DamageReport,
	ElectricalTestReport: ElectricalTestReport,
	FinalInspectionReport: FinalInspectionReport,
	IncomingAlert: IncomingAlertReport,
	InProcessInspectionReport: InProcessInspectionReport,
	NewBearingReport: NewBearingReport,
	OldBearingReport: OldBearingReport,
	TestingAndBalancingReport: TestingAndBalancingReport,
};
