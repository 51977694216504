import {
	bearing,
	shaftTaper,
	shaftOd,
	mechanicalSeal,
	pulley,
	encoder,
	rotaryUnion,
	spacerDistanceSleeve,
	tenon,
	oRings,
	drawbarCollets,
	drawbarSprings,
} from './DamageReportComponents';

export const DamageReport = [
	{
		groupHeader: 'Damage Report',
		components: [
			shaftTaper,
			shaftOd,
			mechanicalSeal,
			pulley,
			encoder,
			rotaryUnion,
			spacerDistanceSleeve,
			tenon,
			oRings,
			drawbarCollets,
			drawbarSprings,
		],
	},
];
